import {CommonConstant, ErrorConstant} from '@rcms/constants';
import {TEntity, TErrorFlag, TFeedbackAction, TOptional} from '@rcms/types';
import CryptoES from 'crypto-es';
import {Translation} from 'primeng/api';

const entityDisplayMapping: Record<TEntity, string> = {
  [CommonConstant.ENTITY.ALARM]: 'Cảnh báo trở ngại',
  [CommonConstant.ENTITY.AREA]: 'Khu vực',
  [CommonConstant.ENTITY.COMPANY]: 'Đơn vị',
  [CommonConstant.ENTITY.DEVICE]: 'Thiết bị',
  [CommonConstant.ENTITY.OTA]: 'OTA',
  [CommonConstant.ENTITY.ROLE]: 'Vai trò',
  [CommonConstant.ENTITY.STATION]: 'Lý trình',
  [CommonConstant.ENTITY.STATION_EVENT]: 'Sự kiện',
  [CommonConstant.ENTITY.STATION_SPECIFICATION]: 'Thông số kỹ thuật',
  [CommonConstant.ENTITY.SYSTEM_CONFIG]: 'Cài đặt chung',
  [CommonConstant.ENTITY.TRAIN_STATION]: 'Nhà ga',
  [CommonConstant.ENTITY.USER]: 'Người dùng',
};

const errorCodeAndDetailToFlag: Partial<Record<string, Partial<Record<string, TErrorFlag>>>> = {
  [ErrorConstant.ERROR_CODE_API.DATA_EXIST]: {
    [CommonConstant.FIELD.ADDRESS]: ErrorConstant.ERROR_FLAG.ADDRESS_EXIST,
    [CommonConstant.FIELD.CODE]: ErrorConstant.ERROR_FLAG.CODE_EXIST,
    [CommonConstant.FIELD.COLOR]: ErrorConstant.ERROR_FLAG.COLOR_EXIST,
    [CommonConstant.FIELD.LEVEL]: ErrorConstant.ERROR_FLAG.LEVEL_EXIST,
    [CommonConstant.FIELD.NAME]: ErrorConstant.ERROR_FLAG.NAME_EXIST,
    [CommonConstant.FIELD.USERNAME]: ErrorConstant.ERROR_FLAG.USERNAME_EXIST,
  },
  [ErrorConstant.ERROR_CODE_API.DATA_USED]: {[CommonConstant.FIELD.DEVICE_ID]: ErrorConstant.ERROR_FLAG.DEVICE_USED},
  [ErrorConstant.ERROR_CODE_API.NOT_FOUND_ENTITY]: {
    [CommonConstant.FIELD.ALARM_ID]: ErrorConstant.ERROR_FLAG.ALARM_NOT_FOUND,
    [CommonConstant.FIELD.AREA_ID]: ErrorConstant.ERROR_FLAG.AREA_NOT_FOUND,
    [CommonConstant.FIELD.COMPANY_ID]: ErrorConstant.ERROR_FLAG.COMPANY_NOT_FOUND,
    [CommonConstant.FIELD.COMPANY_MAINTENANCE_ID]: ErrorConstant.ERROR_FLAG.COMPANY_MAINTENANCE_NOT_FOUND,
    [CommonConstant.FIELD.COMPANY_OBSERVER_ID]: ErrorConstant.ERROR_FLAG.COMPANY_OBSERVER_NOT_FOUND,
    [CommonConstant.FIELD.DEVICE_ID]: ErrorConstant.ERROR_FLAG.DEVICE_NOT_FOUND,
    [CommonConstant.FIELD.ROLE_ID]: ErrorConstant.ERROR_FLAG.ROLE_NOT_FOUND,
    [CommonConstant.FIELD.STATION_EVENT_RESET_ID]: ErrorConstant.ERROR_FLAG.STATION_EVENT_RESET_NOT_FOUND,
  },
};

const feedbackActionToMessage: Record<TFeedbackAction, string> = {
  [CommonConstant.FEEDBACK.ACTION.CREATE]: CommonConstant.FEEDBACK.MESSAGE.CREATE,
  [CommonConstant.FEEDBACK.ACTION.DELETE]: CommonConstant.FEEDBACK.MESSAGE.DELETE,
  [CommonConstant.FEEDBACK.ACTION.UPDATE]: CommonConstant.FEEDBACK.MESSAGE.UPDATE,
};

export class CommonUtil {
  static aesDecrypt(ciphertext: string, passphrase: string): string {
    try {
      const decrypted = CryptoES.AES.decrypt(ciphertext, passphrase).toString(CryptoES.enc.Utf8);
      return decrypted.toString();
    } catch (err) {
      return '';
    }
  }

  static getEntityDisplay(entity: TEntity): string {
    return entityDisplayMapping[entity] ?? entity;
  }

  static getErrorFlagByCodeAndDetail(errorCode: string, errorDetail: string): TOptional<TErrorFlag> {
    return errorCodeAndDetailToFlag[errorCode]?.[errorDetail];
  }

  static getFeedbackMessageFailed(action: TFeedbackAction, entity?: string, messageSuffix?: string): string {
    return [
      feedbackActionToMessage[action],
      entity ? ` ${entity.toLowerCase()}` : '',
      ` ${CommonConstant.FEEDBACK.MESSAGE.FAILED}!`,
      messageSuffix ? ` ${messageSuffix}` : '',
    ].join('');
  }

  static getFeedbackMessageSuccess(action: TFeedbackAction, entity?: string, messageSuffix?: string): string {
    return [
      feedbackActionToMessage[action],
      entity ? ` ${entity.toLowerCase()}` : '',
      ` ${CommonConstant.FEEDBACK.MESSAGE.SUCCESS}!`,
      messageSuffix ? ` ${messageSuffix}` : '',
    ].join('');
  }

  static getItemsPerPageValues(): number[] {
    return Object.values(CommonConstant.PAGING.ITEMS_PER_PAGE);
  }

  static getPreviousPage(page: number): number {
    return Math.max(page - 1, 0);
  }

  static getPrimeNgConfigTranslation(): Translation {
    return {
      clear: 'Xóa',
      dayNames: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
      dayNamesMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
      dayNamesShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
      monthNames: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
      ],
      monthNamesShort: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
      ],
      today: 'Hôm nay',
      weekHeader: 'Tuần',
    };
  }

  static getStartPageDefault(): number {
    return CommonConstant.PAGING.START_PAGE_DEFAULT;
  }

  static jsonReplacer(key: string, value: unknown) {
    // Handling value contains a circular reference
    if (key === 'parent') {
      return null; // Replace circular reference with null
    }
    // Handling BigInt Values
    if (typeof value === 'bigint') {
      return value.toString();
    }
    return value;
  }
}
