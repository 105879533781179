<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputName" class="form-label">Tên cảnh báo</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          class="form-control"
          formControlName="name"
          id="inputName"
          placeholder="Nhập tên"
          type="text"
          [ngClass]="{'is-invalid': form.get('name')?.invalid || feedbackErrors['nameExist']}"
          (focus)="focusInputName()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('name')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('name')?.errors?.['required']">Tên không được để trống</p>
          <p *ngIf="feedbackErrors['nameExist']">Tên đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputLevel" class="form-label">Mức cảnh báo</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          class="form-control"
          formControlName="level"
          id="inputLevel"
          oninput="this.value = this.value.replace(/^0+|[^0-9]/g, '');"
          placeholder="Nhập mức cảnh báo"
          type="text"
          [ngClass]="{'is-invalid': form.get('level')?.invalid || feedbackErrors['levelExist']}"
          (focus)="focusInputLevel()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('level')?.errors?.['required']">Mức cảnh báo không được để trống</p>
          <p *ngIf="form.get('level')?.errors?.['min']">Mức cảnh báo phải lớn hơn 0</p>
          <p *ngIf="feedbackErrors['levelExist']">Mức cảnh báo đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputLevel" class="form-label">Màu cảnh báo</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          class="form-control form-control-color w-100 px-2"
          formControlName="color"
          id="inputColor"
          type="color"
          [ngClass]="{'is-invalid': form.get('color')?.invalid || feedbackErrors['colorExist']}"
          [value]="form.get('color')?.value || '#dc3545'"
          (focus)="focusInputColor()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('color')?.errors?.['required']">Màu cảnh báo không được để trống</p>
          <p *ngIf="feedbackErrors['colorExist']">Màu cảnh báo đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <label for="audioAlarm" class="form-label">Âm thanh cảnh báo</label>
        <div id="audioAlarm">
          <app-audio-upload
            [entityAudioSource]="soundUrl"
            [eventUpload$]="eventUpload$"
            (eventFileSelect)="onFileUploadChange($event)"
            (eventUploadFailed)="onUploadFailed()"
            (eventUploadSuccess)="onUploadSuccess($event)"
          >
          </app-audio-upload>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>
