export class HelperConstant {
  static readonly ENTITY = {
    ALARM: 'Alarm',
    AREA: 'Area',
    COMPANY: 'Company',
    DEVICE: 'Device',
    OTA: 'Ota',
    ROLE: 'Role',
    STATION: 'Station',
    STATION_EVENT: 'StationEvent',
    STATION_SPECIFICATION: 'StationSpecification',
    SYSTEM_CONFIG: 'SystemConfig',
    TRAIN_STATION: 'TrainStation',
    USER: 'User',
  } as const;
}
