export class PermissionConstant {
  static readonly ACTION = {
    CREATE: 'create',
    DELETE: 'delete',
    EXPORT_REPORT: 'export_report',
    READ: 'read',
    READ_REPORT: 'read_report',
    READ_SIMULATION: 'read_simulation',
    UPDATE: 'update',
  } as const;
}
